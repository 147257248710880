<template>
  <b-container>
    <h1>Past Bookings</h1>
    <div>
      <b-row>
        <b-col cols="8">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="bookings_table"
          ></b-pagination>
        </b-col>
        <b-col cols="4">
          <p class="text-right">
            <b-button variant="primary" href="/booking"
              >add new booking
            </b-button>
          </p>
        </b-col>
      </b-row>
      <b-table
        id="bookings_table"
        @row-clicked="bookingDetails"
        striped
        hover
        :items="bookings"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
      >
      </b-table>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Bookings",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      bookings: null,
      fields: [
        { key: "id", sortable: true },
        { key: "guest_name", sortable: true, label: "Guest Name" },
        { key: "check_in", sortable: true },
        { key: "check_out", sortable: true },
        { key: "booking_date", sortable: true },
        { key: "status", sortable: true },
        { key: "bed_name", sortable: true },
      ],
      rows: null,
    };
  },
  async created() {
    await axios
      .get("/api/hhms/bookings/?status=CHECKEDOUT")
      .then((response) => (this.bookings = response.data));
    this.rows = this.bookings.length;
  },
  methods: {
    bookingDetails(booking) {
      if (booking.status === "CHECKEDIN") {
        this.$router.push({
          name: "Checkout",
          params: { bookingId: booking.id },
        });
      } else {
        this.$router.push({
          name: "BookingDetails",
          params: { bookingId: booking.id },
        });
      }
    },
  },
};
</script>

<style scoped></style>
