<template>
  <BookingForm :booking="booking" />
</template>

<script>
import axios from "axios";
import BookingForm from "@/components/BookingForm";
export default {
  name: "BookingDetail",
  components: { BookingForm },
  data() {
    return {
      booking: this.booking,
      show: true,
    };
  },
  async created() {
    await axios
      .get("/api/hhms/bookings/" + this.bookingId)
      .then((response) => (this.booking = response.data));
  },
  props: ["bookingId"],
  methods: {},
};
</script>

<style scoped></style>
