import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwt_decode from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // authUser: localStorage.getItem(JSON.parse("authUser")),
    authUser: [],
    localAuthUser: localStorage.getItem("authUser"),
    isAuthenticated: localStorage.getItem("isAuthenticated"),
    tokenNotExpired: true,
    jwt: localStorage.getItem("token"),
    refresh_jwt: localStorage.getItem("refresh_token"),
    endpoints: {
      obtainJWT: "/api/token/",
      refreshJWT: "/api/token/refresh/",
    },
    user_id: null,
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    authUser(state) {
      return state.authUser;
    },
    localAuthUser(state) {
      return JSON.parse(state.localAuthUser);
    },
  },
  mutations: {
    setAuthUser(state, payload) {
      state.authUser = payload.authUser;
      localStorage.setItem("authUser", JSON.stringify(payload.authUser));
      localStorage.setItem("isAuthenticated", payload.isAuthenticated);
    },
    updateToken(state, tokenData) {
      // TODO: For security purposes, take localStorage out of the project.
      localStorage.setItem("token", tokenData.access);
      localStorage.setItem("refresh_token", tokenData.refresh);
      state.jwt = tokenData.access;
    },
    removeToken(state) {
      // TODO: For security purposes, take localStorage out of the project.
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("authUser");
      localStorage.removeItem("isAuthenticated");

      state.jwt = null;
      state.refresh_jwt = null;
      state.authUser = null;
    },
  },
  actions: {
    obtainToken(username, password) {
      const payload = {
        username: username,
        password: password,
      };

      axios
        .post(this.state.endpoints.obtainJWT, payload)
        .then((response) => {
          this.commit("updateToken", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refreshToken() {
      const payload = {
        token: this.state.refresh_jwt,
      };

      axios
        .post(this.state.endpoints.refreshJWT, payload)
        .then((response) => {
          this.commit("updateToken", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    inspectToken() {
      const token = this.state.jwt;
      if (token) {
        const decoded = jwt_decode(token);
        const exp = decoded.exp;
        const orig_iat = decoded.orig_iat;
        this.state.user_id = decoded.user_id;
        this.state.isAuthenticated = true;
        if (
          exp - Date.now() / 1000 < 1800 &&
          Date.now() / 1000 - orig_iat < 628200
        ) {
          this.dispatch("refreshToken");
        } else if (exp - Date.now() / 1000 < 1800) {
          // DO NOTHING, DO NOT REFRESH
        } else {
          this.state.isAuthenticated = false;
        }
      }
    },
  },
});
