<template>
  <b-container>
    <h1>Transactions</h1>
    <div>
      <b-row>
        <b-col cols="4">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="transaction_table"
          ></b-pagination>
        </b-col>
        <b-col cols="8">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Type to filter data"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-table
        id="transaction_table"
        @filtered="onFiltered"
        striped
        hover
        :items="transactions"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
      ></b-table>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Transactions",
  data() {
    return {
      filter: null,
      transactions: null,
      perPage: 20,
      currentPage: 1,
      sortBy: "payment_date",
      sortDesc: true,
      rows: null,
      fields: [
        { key: "id", sortable: true },
        { key: "type", sortable: true },
        { key: "amount", sortable: true },
        { key: "payment_type", sortable: true },
        { key: "payment_detail", sortable: true },
        { key: "payment_date", sortable: true },
        { key: "status", sortable: true },
        { key: "booking", sortable: true },
      ],
    };
  },
  async mounted() {
    await axios
      .get("/api/hhms/transactions/")
      .then((response) => (this.transactions = response.data));
    this.rows = this.transactions.length;
  },
  methods: {
    transactionDetails(transaction) {
      console.log(transaction);
      this.$router.push({
        name: "TransactionDetails",
        params: { transactionId: transaction.id },
      });
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped></style>
