<template>
  <PaymentForm :booking="booking" />
</template>

<script>
import axios from "axios";
import PaymentForm from "@/components/PaymentForm";
export default {
  name: "Payment",
  components: { PaymentForm },
  data() {
    return {
      booking: this.booking,
      show: true,
    };
  },
  mounted() {
    axios
      .get("/api/hhms/bookings/" + this.bookingId)
      .then((response) => (this.booking = response.data));
  },
  props: ["bookingId"],
  methods: {},
};
</script>

<style scoped></style>
