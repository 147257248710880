import Vue from "vue";
import VueRouter from "vue-router";
import Guests from "../views/Guests.vue";
import Beds from "../views/Beds.vue";
import Transactions from "../views/Transactions.vue";
import Reports from "../views/Reports.vue";
import Home from "../views/Home.vue";
import GuestDetail from "@/views/GuestDetail";
import Bookings from "@/views/Bookings";
import BookingDetails from "@/views/BookingDetails";
import NewGuest from "@/views/NewGuest";
import NewBooking from "@/views/NewBooking";
import Payment from "@/views/Payment";
import Checkout from "@/views/Checkout";
import PastBookings from "@/views/PastBookings";
import EndOfShift from "@/views/EndOfShift";
import DailyCensus from "@/views/DailyCensus";
import Help from "@/views/Help";
import Login from "@/components/Login";
import store from "../store";
import ExitDestinations from "@/views/ExitDestinations";

Vue.use(VueRouter);

const routes = [
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/beds",
    name: "Beds",
    component: Beds,
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/guests",
    name: "Guests",
    component: Guests,
  },
  {
    path: "/guest/:guestId/details",
    name: "GuestDetails",
    component: GuestDetail,
    props: true,
  },
  {
    path: "/guest",
    name: "NewGuest",
    component: NewGuest,
  },
  {
    path: "/booking",
    name: "NewBooking",
    component: NewBooking,
  },
  {
    path: "/bookings",
    name: "Bookings",
    component: Bookings,
  },
  {
    path: "/pastbookings",
    name: "PastBookings",
    component: PastBookings,
  },
  {
    path: "/booking/:bookingId/details",
    name: "BookingDetails",
    component: BookingDetails,
    props: true,
  },
  {
    path: "/checkout/:bookingId/",
    name: "Checkout",
    component: Checkout,
    props: true,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    props: true,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/endofshift",
    name: "EndOfShift",
    component: EndOfShift,
  },
  {
    path: "/dailycensus",
    name: "DailyCensus",
    component: DailyCensus,
  },
  {
    path: "/exitdestinations",
    name: "ExitDestinations",
    component: ExitDestinations,
  },
  {
    path: "/help",
    name: "User Instructions",
    component: Help,
  },
  {
    path: "/login",
    name: "Login Page",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login Page" && !store.getters.isAuthenticated)
    next({ name: "Login Page" });
  else next();
});

export default router;
