<template lang="html">
  <div>
    <br />
    <b-container>
      <b-row class="text-center">
        <b-col></b-col>
        <b-col cols="5"
          ><b-card class="mb-3" header="Login Form">
            <b-form class="login form">
              <div class="field">
                <label for="id_username">Username</label>
                <b-form-input
                  v-model="username"
                  type="text"
                  placeholder="Username"
                  autofocus="autofocus"
                  maxlength="150"
                  id="id_username"
                />
              </div>
              <br />
              <div class="field">
                <label for="id_password">Password</label>
                <b-form-input
                  v-model="password"
                  type="password"
                  placeholder="Password"
                  id="id_password"
                />
              </div>
              <br />
              <b-button
                @click.prevent="authenticate"
                class="button primary"
                type="submit"
              >
                Log In
              </b-button>
            </b-form>
          </b-card></b-col
        >
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    authenticate: function () {
      const payload = {
        username: this.username,
        password: this.password,
      };
      axios
        .post(this.$store.state.endpoints.obtainJWT, payload)
        .then((response) => {
          this.$store.commit("updateToken", response.data);
          this.$store.dispatch("inspectToken");
          // get and set auth user
          const base = {
            headers: {
              // Set your Authorization to 'JWT', not Bearer!!!
              Authorization: `JWT ${this.$store.state.jwt}`,
              "Content-Type": "application/json",
            },
            xhrFields: {
              withCredentials: true,
            },
          };
          const axiosInstance = axios.create(base);
          axiosInstance({
            url: "/api/hhms/user/" + this.$store.state.user_id,
            method: "get",
            params: {},
          }).then((response) => {
            this.$store.commit("setAuthUser", {
              authUser: response.data,
              isAuthenticated: true,
            });
            this.$router.push({ name: "Home" });
          });
        })
        .catch((error) => {
          console.log(error);
          console.debug(error);
          console.dir(error);
        });
    },
  },
};
</script>

<style lang="css"></style>
