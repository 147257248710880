<template>
  <b-container>
    <h1>Exit Destinations</h1>
    <div>
      <b-row>
        <b-col cols="4">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="destination_table"
          ></b-pagination>
        </b-col>
        <b-col cols="8">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Type to filter data"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-table
        id="destination_table"
        @filtered="onFiltered"
        striped
        hover
        :items="locations"
        :fields="fields"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
      ></b-table>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Exit Destinations",
  data() {
    return {
      filter: null,
      locations: null,
      perPage: 20,
      currentPage: 1,
      sortBy: "guest",
      sortDesc: true,
      rows: null,
      fields: [
        { key: "id", sortable: true },
        { key: "location", sortable: true, lable: "Location" },
        { key: "guest_name", sortable: true, label: "Guest Name" },
        { key: "booking", sortable: true, label: "Booking id" },
        { key: "create_date", sortable: true, label: "Exit Date" },
      ],
    };
  },
  async mounted() {
    await axios
      .get("/api/hhms/locations/")
      .then((response) => (this.locations = response.data));
    this.rows = this.locations.length;
    console.log(this.locations);
  },
  methods: {
    exitLocationDetails(location) {
      console.log(location);
      this.$router.push({
        name: "ExitDetails",
        params: { locationId: location.id },
      });
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped></style>
