<template>
  <div>
    <br />
    <b-container>
      <b-modal ref="extend-modal" hide-footer title="How long to extend stay?">
        <b-row>
          <b-col>
            <b-button @click.self="updateStayDates(1)" variant="light" center
              >1 Day
            </b-button>
          </b-col>
          <b-col>
            <b-button @click.self="updateStayDates(7)" variant="light" center
              >1 Week
            </b-button>
          </b-col>
          <b-col>
            <b-button @click.self="updateStayDates(30)" variant="light" center
              >30 Days
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        ref="exit-modal"
        title="What is the exit destination of this guest?"
        @ok="checkoutForm"
      >
        <b-row>
          <b-col>
            <b-form-group
              id="exit-destination-group-1"
              label="Exit Destinations:"
              label-for="input-1"
              description="The location the guest went after checkout"
            >
              <b-form-select
                v-model="exitLocation.location"
                :options="exitDestinations"
                value-field="id"
                text-field="location"
                required
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-card class="mb-3" header="Checkout Form">
        <b-form v-if="show" class="p-3" align="left">
          <b-form-group
            id="booking-group-1"
            label="Booking Date:"
            label-for="input-1"
            description="The date this booking was made."
          >
            <b-form-input
              id="input-1"
              v-model="formData.booking_date"
              type="date"
              :disabled="true"
              placeholder="Booking Date"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            id="booking-group-2"
            label="Checkin Date:"
            label-for="input-2"
            description="The anticipated checkin date."
          >
            <b-form-input
              id="input-2"
              v-model="formData.check_in"
              type="date"
              :disabled="true"
              placeholder="Checkin Date"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            id="booking-group-3"
            label="Checkout Date:"
            label-for="input-3"
            description="The checkout date."
          >
            <b-form-input
              id="input-3"
              v-model="formData.check_out"
              type="date"
              placeholder="Checkout Date"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            id="booking-group-4"
            label="Status:"
            label-for="input-4"
            description="The status."
          >
            <b-form-select
              v-model="formData.status"
              :options="statuses"
              :disabled="true"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="booking-group-5"
            label="Bed:"
            label-for="input-5"
            description="The bed reserved."
          >
            <b-form-select
              v-model="formData.bed"
              :options="empties"
              value-field="id"
              text-field="bed_name"
              :disabled="true"
              required
            >
            </b-form-select>
          </b-form-group>
          <b-form-group
            id="booking-group-6"
            label="Guest:"
            label-for="input-6"
            description="The guest making reservation."
          >
            <b-form-select
              v-model="formData.guest"
              :options="guests"
              value-field="id"
              text-field="full_name"
              :disabled="true"
              required
            >
            </b-form-select>
          </b-form-group>
          <div class="hide-print">
            <b-button-group>
              <b-row>
                <b-col>
                  <b-button
                    @click="showExitModal()"
                    variant="primary"
                    style="padding: 5px"
                    >Checkout
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    @click="showModal()"
                    variant="secondary"
                    style="padding: 5px"
                  >
                    Extend
                  </b-button>
                </b-col>
              </b-row>
            </b-button-group>
          </div>
        </b-form>
        <br />
        <div v-if="showRefund">
          <b-card
            id="refund-receipt"
            title="Refund Due"
            tag="article"
            style="max-width: 30rem"
            class="mb-2 pt-2"
          >
            <div>
              <b-table
                striped
                hover
                :items="refund_data"
                :fields="refund_fields"
              ></b-table>
            </div>
          </b-card>
        </div>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import printJS from "print-js";
import {
  getExitDestinations,
  getGuests,
  getOccupiedBeds,
  status,
} from "@/helpers.js";

export default {
  name: "CheckoutForm",
  data() {
    return {
      show: true,
      submitted: false,
      newBooking: false,
      statuses: status,
      empties: [],
      guests: [],
      exitDestinations: [],
      showLateFee: false,
      start_date: null,
      end_date: null,
      newExtendedBooking: null,
      exitLocation: {},
      exitLocationData: {},
      refundDisplay: false,
      refundAmount: 0,
      numOfDaysUsed: 0,
      MS_PER_DAY: 1000 * 60 * 60 * 24,
      refund_fields: [
        { key: "guest_name", label: "Guest Name" },
        { key: "num_days_used", label: "Number of Days Used" },
        { key: "refund_amount", label: "Amount of Refund" },
      ],
    };
  },
  props: {
    booking: Object,
  },
  computed: {
    formData: function () {
      return this.booking || {};
    },
    lateFee: function () {
      const beforeDate = new Date();
      const today = beforeDate.getUTCDate();
      const checkout_date = new Date(this.formData.check_out).getUTCDate();
      console.log(today);
      console.log(checkout_date);
      const diffInDays = today - checkout_date;
      console.log(diffInDays);
      let fee = 0;
      if (diffInDays > 0) {
        fee = Math.round(diffInDays, 1) * 1.5;
      } else {
        fee = 0;
      }
      return fee;
    },
    today: function () {
      const today_date = new Date();
      return today_date;
    },
    checkout_date: function () {
      const checkout = new Date(this.formData.check_out);
      return checkout;
    },
    checkin_date: function () {
      const checkin = new Date(this.formData.check_in);
      return checkin;
    },
    daysUsed: function () {
      const daysUsed = Math.abs(Math.floor(
        (this.today - this.checkin_date) / this.MS_PER_DAY
      ));
      return daysUsed;
    },
    daysPaid: function () {
      const daysPaid = Math.floor(
        (this.checkout_date - this.checkin_date) / this.MS_PER_DAY
      );
      return daysPaid;
    },
    refundCalc: function () {
      const daysPaid = Math.floor(
        (this.checkout_date - this.checkin_date) / this.MS_PER_DAY
      );
      const daysUsed = Math.floor(
        (this.today - this.checkin_date) / this.MS_PER_DAY
      );
      let refundDue = 0;
      switch (true) {
        case daysPaid < 8: {
          let paid = daysPaid * 15;
          if (daysPaid == 7) {
            paid = 70;
          }
          refundDue = paid - daysUsed * 15; // weekly rate 65- (days used * 15)
          if (refundDue < 0) {
            refundDue = 0;
          }
          break;
        }
        case daysPaid > 7 && daysPaid < 30: {
          const paid = daysPaid * 10;
          console.log("paid:" + daysPaid);
          const numweeks = Math.round(daysUsed / 7);
          console.log("numweeeks:" + numweeks);
          const remain = Math.abs((daysUsed / 7 - numweeks) * 7);
          console.log("remain:" + remain);
          refundDue = Math.abs(paid - numweeks * 7 * 10 - 15 * remain);
          break;
        }
        case daysPaid >= 30: {
          const paid = daysPaid * 8.33333;
          console.log("paid:" + daysPaid);
          const numweeks = Math.round(daysUsed / 7);
          console.log("numweeeks:" + numweeks);
          const remain = Math.abs(Math.round((daysUsed / 7 - numweeks) * 7));
          console.log("remain:" + remain);
          refundDue = Math.abs(paid - numweeks * 7 * 10 - 15 * remain);
          break;
        }
        default:
          break;
      }
      console.log("refund due is :" + refundDue);
      return refundDue.toFixed(2);
    },
    refund_data: function () {
      return [
        {
          guest_name: this.formData.guest_name,
          num_days_used: this.daysUsed,
          refund_amount: this.refundCalc,
        },
      ];
    },
    showRefund: function () {
      if (this.refundCalc > 0) {
        return true;
      }
      return false;
    },
  },
  async created() {
    const response = await getOccupiedBeds();
    this.empties = response.data;
    console.log(response.data);
    const gresponse = await getGuests();
    this.guests = gresponse.data;
    const destinationResponse = await getExitDestinations();
    this.exitDestinations = destinationResponse.data;
  },
  methods: {
    printRefund() {
      printJS({
        printable: "refund-receipt",
        properties: this.refund_fields,
        type: "html",
      });
    },
    async checkoutForm() {
      // add time to checkin and checkout fields
      // this.formData.check_in = `${this.formData.check_in}T05:00`;
      // this.formData.check_out = `${this.formData.check_out}T05:00`;
      this.formData.check_in = `${this.formData.check_in}`;
      this.formData.check_out = `${this.formData.check_out}`;
      this.formData.status = "CHECKEDOUT";
      const response = await axios.patch(
        "/api/hhms/bookings/" + this.formData.id + "/",
        { status: this.formData.status }
      );
      this.formData = response.data;
      const bed_response = await axios.patch(
        "/api/hhms/beds/" + this.formData.bed + "/",
        { status: "EMPTY" }
      );
      console.log(bed_response);
      const guest_response = await axios.patch(
        "/api/hhms/guests/" + this.formData.guest + "/",
        { balance: this.lateFee }
      );
      console.log(guest_response);
      // add exit destinatoin api call here
      const destination_response = await axios.post("/api/hhms/locations/", {
        past_guest: this.formData.guest,
        booking: this.booking.id,
        destination: this.exitLocation.location,
      });
      console.log(destination_response);
      console.log(this.refundCalc);
      this.$router.push("/bookings");
    },
    // async exitDestination() {},
    async extendBedForm() {
      // add time to checkin and checkout fields
      // this.formData.check_in = `${this.formData.check_in}T12:00-0500`;
      // this.formData.check_out = `${this.formData.check_out}T12:00-0500`;
      // this.formData.check_in = `${this.formData.check_in}T17:00`;
      // this.formData.check_out = `${this.formData.check_out}T1700`;
      this.formData.status = "CHECKEDOUT";
      const response = await axios.patch(
        "/api/hhms/bookings/" + this.formData.id + "/",
        { status: this.formData.status }
      );
      this.formData = response.data;
      console.log(response)
      const bed_response = await axios.patch(
        "/api/hhms/beds/" + this.formData.bed + "/",
        { status: "EMPTY" }
      );
      console.log(bed_response);
      const guest_response = await axios.patch(
        "/api/hhms/guests/" + this.formData.guest + "/",
        { balance: this.lateFee }
      );
      console.log(guest_response);
      // start the process of a new booking

      // this.$router.push("/bookings");
    },
    addDays(date, days) {
      const copy = new Date(date)
      copy.setDate(copy.getDate() + days)
      return copy.toISOString();
    },
    add30Days(datetime, days) {
      let newDatetime = new Date(datetime);
      newDatetime.setDate(newDatetime.getDate() + days);
      return newDatetime.toISOString();
    },
    async updateStayDates(days) {
      // update new booking and push to payment
      const newCheckout = this.formData.check_out;
      // this.newExtendedBooking.check_in = `${this.formData.check_out}T12:00`;
      // const newCheckout = this.formData.check_out
      // this.newExtendedBooking.check_in = `${this.formData.check_out}T12:00`;
      this.newExtendedBooking.check_in = new Date(newCheckout).toISOString();
      //const old_checkout = this.add30Days(this.formData.check_out, days);
      // old_checkout.setDate(old_checkout.getDate() + days);
      //const old_checkout = this.addDays(this.formData.check_out, days);
      const old_checkout = this.addDays(newCheckout, days);
      // old_checkout.setDate(old_checkout.getDate());
      // this.newExtendedBooking.check_out = `${old_checkout.toLocaleDateString(
      //   "sv"
      // )}T12:00`;
      this.newExtendedBooking.check_out = old_checkout;
      this.newExtendedBooking.status = "RESERVED";
      console.log(this.newExtendedBooking);

      // checkout guest first
      await this.extendBedForm();

      this.$forceUpdate();
      const response = await axios.post(
        "/api/hhms/bookings/",
        this.newExtendedBooking
      );
      this.newExtendedBooking = response.data;
      console.log(this.newExtendedBooking)

      this.$router.push({
        name: "Payment",
        params: { bookingId: this.newExtendedBooking.id },
      });
    },
    extendStay() {
      this.$router.push({
        name: "Payment",
        params: { bookingId: this.newExtendedBooking.id },
      });
    },
    showModal() {
      console.log(this.formData);
      this.newExtendedBooking = JSON.parse(JSON.stringify(this.formData));
      console.log(this.newExtendedBooking);
      this.$refs["extend-modal"].show();
    },
    showExitModal() {
      this.$refs["exit-modal"].show();
    },
    isEmptyObject() {
      if (this.booking == null) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
@media print {
  .hide-print {
    display: none;
  }
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
