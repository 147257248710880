<template>
  <b-container>
    <h1>Bookings</h1>
    <div>
      <b-modal
        id="change-bed-modal"
        centered
        v-bind:title="guestNameTitle"
        @ok="updateBed"
        ok-title="Change Bed"
        ok-variant="outline-danger"
      >
        <b-form-group id="old-bed" description="The Bed the Guest is Leaving.">
          <div>
            Old Bed: <b>{{ this.oldBedName }}</b>
          </div>
        </b-form-group>
        <b-form-group
          id="empty-bed-dropdown"
          label="Select New Bed:"
          label-for="input-5"
          description="The Empty Bed."
        >
          <b-form-select
            v-model="newBed"
            :options="empties"
            value-field="id"
            text-field="bed_name"
            required
          >
          </b-form-select>
        </b-form-group>
      </b-modal>
    </div>
    <div>
      <b-row>
        <b-col cols="3">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="bookings_table"
          ></b-pagination>
        </b-col>
        <b-col cols="6">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Type to filter data"
          ></b-form-input>
        </b-col>
        <b-col cols="3">
          <p class="text-right">
            <b-button variant="primary" href="/booking"
              >add new booking
            </b-button>
          </p>
        </b-col>
      </b-row>
      <b-table
        @row-clicked="bookingDetails"
        striped
        hover
        :items="bookings"
        :fields="fields"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template v-slot:cell(actions)="{ item }">
          <b-button
            v-if="isCheckedIn(item)"
            v-b-modal.change-bed-modal
            variant="outline"
          >
            <b-icon-three-dots @click="changeBedModal(item)" />
          </b-button>
        </template>
      </b-table>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import { getEmptyBeds } from "@/helpers.js";

export default {
  name: "Bookings",
  data() {
    return {
      filter: null,
      bookings: null,
      empties: null,
      oldBed: null,
      oldBedName: null,
      guestNameTitle: null,
      newBed: null,
      bedChangeBookingId: null,
      perPage: 20,
      currentPage: 1,
      sortBy: "check_out",
      sortDesc: true,
      rows: null,
      fields: [
        { key: "id", sortable: true },
        { key: "guest_name", sortable: true, label: "Guest Name" },
        { key: "check_in", sortable: true },
        { key: "check_out", sortable: true },
        { key: "booking_date", sortable: true },
        { key: "status", sortable: true },
        { key: "bed_name", sortable: true },
        { key: "actions" },
      ],
    };
  },
  async created() {
    await axios
      .get("/api/hhms/bookings/?current=True")
      .then((response) => (this.bookings = response.data));
    this.rows = this.bookings.length;
  },
  methods: {
    bookingDetails(booking) {
      if (booking.status === "CHECKEDIN") {
        this.$router.push({
          name: "Checkout",
          params: { bookingId: booking.id },
        });
      } else {
        this.$router.push({
          name: "BookingDetails",
          params: { bookingId: booking.id },
        });
      }
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async changeBedModal(item) {
      const response = await getEmptyBeds();
      this.empties = response.data;
      this.oldBed = item.bed;
      this.oldBedName = item.bed_name;
      this.guestNameTitle = "Changing Bed For: " + item.guest_name;
      this.bedChangeBookingId = item.id;

      console.log("changed bed:" + item);
    },
    async updateBed() {
      await axios
        .patch("/api/hhms/bookings/" + this.bedChangeBookingId + "/", {
          bed: this.newBed,
        })
        .then((new_bed) => {
          axios.patch("/api/hhms/beds/" + this.newBed + "/", {
            status: "OCCUPIED",
          });
          const changed_booking = this.bookings.findIndex(
            (item) => item.id === this.bedChangeBookingId
          );
          console.log(this.bookings[changed_booking]);
          this.bookings[changed_booking].bed_name = new_bed.data.bed_name;
        })
        .then(() => {
          axios.patch("/api/hhms/beds/" + this.oldBed + "/", {
            status: "EMPTY",
          });
        })
        .then(() => {
          this.$bvToast.toast(`Bed changed sucessfully.`, {
            title: "Bed Changed Alert",
            variant: "success",
            solid: false,
            noAutoHide: false,
            bodyClass: "mb-4",
          });
        })
        .catch((error) => {
          this.$bvToast.toast(`Bed changed unsucessful:` + error.message, {
            title: "Bed Not Changed Alert",
            variant: "danger",
            solid: false,
            noAutoHide: false,
            bodyClass: "mb-4",
          });
        });
    },
    isCheckedIn(item) {
      return item.status === "CHECKEDIN";
    },
  },
};
</script>

<style scoped></style>
