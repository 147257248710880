<template>
  <b-container id="transaction-table">
    <h1>End of Shift Report: {{ localAuthUser.username }}</h1>
    <div>
      <b-modal
        ref="eof-modal"
        title="Pick Start and End of Shift"
        ok-only
        @ok="transactionRequest"
      >
        <b-form-group
          id="start-shift-group-2"
          label="Start Date:"
          label-for="input-2"
          description="The anticipated checkin date."
        >
          <b-form-input
            id="input-2"
            v-model="start_date"
            type="date"
            placeholder="Start Date"
            required
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          id="start-time-shift-group-2"
          label="Start Time:"
          label-for="input-3"
          description="The shift start time."
        >
          <b-form-input
            id="input-3"
            v-model="start_time"
            type="time"
            placeholder="Start Time"
            required
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          id="end-shift-group-3"
          label="End Date:"
          label-for="input-3"
          description="The shift end date."
        >
          <b-form-input
            id="input-3"
            v-model="end_date"
            type="date"
            placeholder="End Date"
            required
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          id="end-time-shift-group-3"
          label="End Time:"
          label-for="input-3"
          description="The shift end time."
        >
          <b-form-input
            id="input-3"
            v-model="end_time"
            type="time"
            placeholder="End Time"
            required
          >
          </b-form-input>
        </b-form-group>
      </b-modal>
      <b-table
        @row-clicked="transactionDetails"
        striped
        hover
        :items="transactions"
      >
        <template #foot(type)>
          <span class="text-primary">Total</span>
        </template>
        <template #foot(amount)>
          <span class="text-primary">$ {{ transaction_totals }}</span>
        </template>
      </b-table>
    </div>
    <div class="left">
      <h3>Total Transaction Amount this shift: ${{ transaction_totals }}</h3>
    </div>
    <div>
      <b-button @click="printReport">Print Report</b-button>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import printJS from "print-js";
import { mapGetters } from "vuex";

export default {
  name: "EndOfShift",
  data() {
    return {
      transactions: null,
      start_date: null,
      start_time: null,
      end_date: null,
      end_time: null,
      transaction_totals: null,
    };
  },
  mounted() {
    this.$refs["eof-modal"].show();
    const today = new Date();
    const daystart = `${today.toISOString()}`;
    const dayend = today.toLocaleDateString("sv");
    axios
      .get("/api/hhms/transactions/?start=" + daystart + "&end=" + dayend)
      .then((response) => (this.transactions = response.data));
  },
  computed: {
    ...mapGetters(["authUser", "localAuthUser", "isAuthenticated"]),
  },
  methods: {
    transactionDetails(transaction) {
      console.log(transaction);
      this.$router.push({
        name: "TransactionDetails",
        params: { transactionId: transaction.id },
      });
    },
    async transactionRequest() {
      const start = `${this.start_date}T${this.start_time}`;
      const end = `${this.end_date}T${this.end_time}`;

      await axios
        .get("/api/hhms/transactions/?start=" + start + "&end=" + end)
        .then((response) => (this.transactions = response.data));

      this.transaction_totals = this.transactions.reduce(
        (n, { amount }) => n + amount,
        0
      );
    },
    printReport() {
      printJS({
        printable: "transaction-table",
        header: `Transaction Report: User: ${this.localAuthUser.username}`,
        properties: [
          { field: "id", displayName: "Transaction ID" },
          { field: "type", displayName: "Transaction Type" },
          { field: "amount", displayName: "Amount" },
          { field: "payment_detail", displayName: "Payment ID" },
          { field: "booking", displayName: "Booking ID" },
        ],
        type: "html",
      });
    },
  },
};
</script>

<style scoped></style>
