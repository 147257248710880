import axios from "axios";

export let status = [
  "RESERVED",
  "OCCUPIED",
  "EMPTY",
  "CHECKEDIN",
  "CHECKEDOUT",
];
export let bed_status = ["RESERVED", "OCCUPIED", "EMPTY", "DIRTY"];
export let payment_types = ["CARD", "CASH", "VOUCHER"];

export function getEmptyBeds() {
  return axios.get("/api/hhms/beds/?status=EMPTY");
}

export function getCheckedInBeds() {
  return axios.get("/api/hhms/beds/?status=CHECKEDIN");
}

export function getOccupiedBeds() {
  return axios.get("/api/hhms/beds/?status=OCCUPIED");
}

export function getGuests() {
  return axios.get("/api/hhms/guests/");
}

export function getGuestsNoBed() {
  return axios.get("/api/hhms/guests/");
}

export function getPaymentAmounts() {
  return axios.get("/api/hhms/payments/");
}

export function getGuest(id) {
  return axios.get("/api/hhms/guests/" + id);
}

export function getBed(id) {
  return axios.get("/api/hhms/beds/" + id);
}

export function getExitDestinations() {
  return axios.get("/api/hhms/destinations");
}
