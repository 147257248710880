<template>
  <b-card border-variant="secondary" header="Empty Beds" align="center">
    <div>
      <div class="text-primary text-center mt-2">
        <h1>{{ bed_count }}</h1>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from "axios";

export default {
  name: "EmptyBedsCard",
  data() {
    return {
      bed_count: null,
      fields: [
        { key: "id", sortable: true },
        { key: "bed_name", sortable: true },
      ],
    };
  },
  async created() {
    await axios
      // .get("/api/hhms/beds/?status=EMPTY")
      .get("/api/hhms/emptybeds")
      .then((response) => (this.bed_count = response.data));
  },
  methods: {
    bedDetails() {
      this.$router.push({ name: "Bookings" });
    },
  },
};
</script>

<style scoped></style>
