<template>
  <b-container>
    <h3>New Guest Form</h3>
    <GuestForm v-on:submit="createGuest" />
  </b-container>
</template>

<script>
import GuestForm from "@/components/GuestForm";
export default {
  name: "NewGuest",
  components: { GuestForm },
  data() {
    return {
      guest: null,
      show: true,
    };
  },
  methods: {
    createGuest() {},
  },
};
</script>

<style scoped></style>
