<template>
  <b-container>
    <div>
      <h1>Daily Census Report</h1>
    </div>
    <b-table
      id="guest_table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @row-clicked="guestDetails"
      striped
      hover
      :fields="fields"
      :items="guests"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
    >
    </b-table>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  name: "DailyCensus",
  data() {
    return {
      guests: null,
      fields: [{ key: "id" }, { key: "guest", sortable: true, label: "Guest" }],
    };
  },
  async created() {
    await axios
      .get("/api/hhms/beds/?status=OCCUPIED")
      .then((response) => (this.guests = response.data));
    console.log(this.guests);
  },
};
</script>

<style scoped></style>
