<template>
  <GuestForm :guest="guest" />
</template>

<script>
import axios from "axios";
import GuestForm from "@/components/GuestForm";
export default {
  name: "GuestDetail",
  components: { GuestForm },
  data() {
    return {
      guest: this.guest,
      show: true,
      loading: false,
      post: null,
      error: null,
    };
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await axios
        .get("/api/hhms/guests/" + this.guestId)
        .then((response) => (this.guest = response.data));
    },
  },
  mounted() {},
  props: ["guestId"],
};
</script>

<style scoped></style>
