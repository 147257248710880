<template>
  <div>
    <br />
    <b-container>
      <b-card class="mb-3" header="Booking Detail Form">
        <b-form v-if="show" class="p-3" align="left">
          <b-form-group
            id="booking-group-1"
            label="Booking Date:"
            label-for="input-1"
            description="The date this booking was made."
          >
            <b-form-input
              id="input-1"
              v-model="formData.booking_date"
              :disabled="disableBookingDate == 1"
              type="date"
              placeholder="Booking Date"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-row v-if="isEmptyObject() == true">
            <b-col cols="4"
              ><div class="text-center">
                <b-button
                  @click.self="updateStayDates(1)"
                  variant="light"
                  center
                  >1 Day</b-button
                >
              </div></b-col
            >
            <b-col cols="4"
              ><div class="text-center">
                <b-button
                  @click.self="updateStayDates(7)"
                  variant="light"
                  center
                  >1 Week</b-button
                >
              </div></b-col
            >
            <b-col cols="4"
              ><div class="text-center">
                <b-button
                  @click.self="updateStayDates(30)"
                  variant="light"
                  center
                  >30 Days</b-button
                >
              </div></b-col
            >
          </b-row>
          <b-form-group
            id="booking-group-2"
            label="Checkin Date:"
            label-for="input-2"
            description="The anticipated checkin date."
          >
            <b-form-input
              id="input-2"
              v-model="formData.check_in"
              type="date"
              placeholder="Checkin Date"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            id="booking-group-3"
            label="Checkout Date:"
            label-for="input-3"
            description="The checkout date."
          >
            <b-form-input
              id="input-3"
              v-model="formData.check_out"
              type="date"
              placeholder="Checkout Date"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            id="booking-group-4"
            label="Status:"
            label-for="input-4"
            description="The status."
          >
            <b-form-select
              v-model="formData.status"
              :options="statuses"
              :disabled="1"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="booking-group-5"
            label="Bed:"
            label-for="input-5"
            description="The bed reserved."
          >
            <b-form-select
              v-model="formData.bed"
              :options="empties"
              value-field="id"
              text-field="bed_name"
              required
            >
            </b-form-select>
          </b-form-group>
          <b-form-group
            id="booking-group-6"
            label="Guest:"
            label-for="input-6"
            description="The guest making reservation."
          >
            <v-select
              label="full_name"
              :options="guests"
              :reduce="(full_name) => full_name.id"
              v-model="formData.guest"
            >
            </v-select>
          </b-form-group>
          <div>
            <b-button-group>
              <b-button type="reset" variant="danger" style="padding: 5px"
                >Reset</b-button
              >
              <b-button
                v-if="isEmptyObject() == true"
                @click="createBooking()"
                variant="primary"
                style="padding: 5px"
                >Create</b-button
              >
              <b-button
                v-if="isEmptyObject() == false"
                @click="takePayment()"
                variant="success"
                style="padding: 5px"
                >Payment</b-button
              >
              <b-button
                v-if="isEmptyObject() == false"
                @click="onSubmit()"
                variant="primary"
                style="padding: 5px"
                >Update</b-button
              >
            </b-button-group>
          </div>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { status } from "@/helpers.js";
import { getEmptyBeds } from "@/helpers.js";
import { getGuests } from "@/helpers.js";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "BookingForm",
  components: { vSelect },
  data() {
    return {
      show: true,
      submitted: false,
      newBooking: false,
      statuses: status,
      empties: Array,
      guests: [],
      timestamp: "",
      disableBookingDate: 0,
      formData: {},
    };
  },
  props: {
    booking: Object,
  },
  // computed: {
  //   formData: function () {
  //     this.refreshKey;
  //     return this.booking || {};
  //   },
  // },
  async created() {
    const response = await getEmptyBeds();
    this.empties = response.data;
    const gresponse = await getGuests();
    this.guests = gresponse.data;
    if (this.booking) {
      this.formData = this.booking;
    }
  },
  mounted() {
    if (this.formData.booking_date === undefined) {
      this.formData.booking_date = this.getNow();
      this.disableBookingDate = 1;
    }
  },
  methods: {
    async onSubmit() {
      this.formData.check_in = `${this.formData.check_in}T12:00-0500`;
      this.formData.check_out = `${this.formData.check_out}T12:00-0500`;
      const response = await axios.patch(
        "/api/hhms/bookings/" + this.formData.id + "/",
        this.formData
      );
      this.formData = response.data;
    },
    async createBooking() {
      // add time to checkin and checkout fields
      this.formData.check_in = `${this.formData.check_in}T12:00`;
      this.formData.check_out = `${this.formData.check_out}T12:00`;
      const response = await axios.post("/api/hhms/bookings/", this.formData);
      this.formData = response.data;
      this.$router.push("/bookings");
    },
    takePayment() {
      this.$router.push({
        name: "Payment",
        params: { bookingId: this.formData.id },
      });
    },
    isEmptyObject() {
      if (this.booking == null) {
        this.formData.status = "RESERVED";
        return true;
      } else {
        return false;
      }
    },
    getNow() {
      const today = new Date();
      return today.toLocaleDateString("sv");
    },
    updateStayDates(days) {
      const today = this.getNow();
      let end = new Date();
      end.setDate(end.getDate() + days);
      this.formData.check_in = today;
      this.formData.check_out = end.toLocaleDateString("sv");
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped></style>
