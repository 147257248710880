<template>
  <b-container>
    <h1>Beds</h1>
    <div>
      <b-table
        striped
        hover
        :items="beds"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
      ></b-table>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Beds",
  data() {
    return {
      sortBy: "status",
      sortDesc: true,
      beds: null,
    };
  },
  async created() {
    await axios
      .get("/api/hhms/beds/")
      .then((response) => (this.beds = response.data));
  },
  methods: {
    bedDetails(bed) {
      console.log(bed);
      this.$router.push({ name: "BedDetails", params: { bedId: bed.id } });
    },
  },
};
</script>

<style scoped></style>
