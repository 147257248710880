<template>
  <b-container>
    <h3>New Booking Form</h3>
    <BookingForm v-on:submit="createBooking" />
  </b-container>
</template>

<script>
import BookingForm from "@/components/BookingForm";
export default {
  name: "NewBooking",
  components: { BookingForm },
  data() {
    return {
      booking: null,
      show: true,
    };
  },
  methods: {
    createBooking() {},
  },
};
</script>

<style scoped></style>
