<template>
  <CheckoutForm :booking="booking" />
</template>

<script>
import axios from "axios";
import CheckoutForm from "@/components/CheckoutForm";
export default {
  name: "Checkout",
  components: { CheckoutForm },
  data() {
    return {
      booking: this.booking,
      show: true,
    };
  },
  async created() {
    await axios
      .get("/api/hhms/bookings/" + this.bookingId)
      .then((response) => (this.booking = response.data));
  },
  props: ["bookingId"],
  methods: {},
};
</script>

<style scoped></style>
