<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <h1>Harmony House Bed Management System</h1>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-card-group deck>
        <CheckoutsCard />
        <EmptyBedsCard />
        <DailyCensusCard />
      </b-card-group>
    </b-row>
  </b-container>
</template>

<script>
import CheckoutsCard from "@/components/CheckoutsCard";
import EmptyBedsCard from "@/components/EmptyBedsCard";
import DailyCensusCard from "@/components/DailyCensusCard";
export default {
  name: "Home",
  components: { EmptyBedsCard, CheckoutsCard, DailyCensusCard },
};
</script>

<style scoped></style>
