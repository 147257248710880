<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand href="/">
        <b-img
          :src="logo"
          width="50"
          height="50"
          class="m1"
          rounded
          alt="Harmony House Logo"
          >Home
        </b-img>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown text="Tasks" right>
            <b-dropdown-item href="/guests">Guest List</b-dropdown-item>
            <b-dropdown-item href="/bookings">Bookings</b-dropdown-item>
            <b-dropdown-item href="/pastbookings"
              >Past Bookings
            </b-dropdown-item>
            <b-dropdown-item href="/beds">Bed List</b-dropdown-item>
            <b-dropdown-item href="/transactions">Transactions</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="/endofshift">End of Shift</b-dropdown-item>
            <b-dropdown-item href="/dailycensus">Daily Census</b-dropdown-item>
            <b-dropdown-item href="/exitdestinations"
              >Exit Destination
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="/help">User Instructions</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item href="/guests">Guests</b-nav-item>
          <b-nav-item href="/bookings">Bookings</b-nav-item>
          <b-nav-item href="/transactions">Transactions</b-nav-item>
          <b-nav-item href="/beds">Beds</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!--        <b-nav-form>-->
          <!--          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>-->
          <!--          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>-->
          <!--        </b-nav-form>-->

          <b-nav-item-dropdown
            v-if="userName !== null || userName !== 'undefined'"
            right
          >
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em v-if="localAuthUser">{{ localAuthUser.username }}</em>
              <em v-else>{{ authUser.username }}</em>
            </template>

            <b-dropdown-item href="/home" @click="logout"
              >Sign Out
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      logo: process.env.BASE_URL + "/static/hhms/hhicon.png",
    };
  },
  computed: {
    ...mapGetters(["authUser", "localAuthUser", "isAuthenticated"]),
  },
  methods: {
    logout() {
      this.$store.commit("removeToken");
    },
  },
};
</script>

<style scoped></style>
