<template>
  <div>
    <h1>User Instructions</h1>
    <div class="align-left">
      <ul class="text-left">
        <li>
          <h3>1. Booking</h3>
          <p>
            a. This is the basic element. A booking needs to be created before a
            guest can be checked in.
          </p>
        </li>
        <li>
          <h3>2. Checkin</h3>
          <p>
            a. Once a booking is created, a guest can be checked in by using the
            booking on the booking form page. When a row is clicked, you can
            then take payment.
          </p>
        </li>
        <li>
          <h3>3. Payment</h3>
          <p>
            a. Once a guest is checkedin, at the bottom of the screen you will
            see the payment button click that. You take payment in square, then
            place the receipt number in the box.
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Help",
};
</script>

<style scoped></style>
