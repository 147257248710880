<template>
  <b-card border-variant="secondary" header="Today's Checkouts" align="center">
    <div>
      <b-table
        @row-clicked="bookingDetails"
        hover
        :items="bookings"
        :fields="fields"
        :borderless="true"
        class="font-weight-bold"
      ></b-table>
    </div>
  </b-card>
</template>

<script>
import axios from "axios";

export default {
  name: "CheckoutsCard",
  data() {
    return {
      bookings: null,
      fields: [
        {
          key: "guest_name",
          sortable: true,
          label: "Guest Name",
          thStyle: {
            display: "none",
          },
        },
      ],
    };
  },
  async created() {
    const day = new Date();
    const today = day.toLocaleDateString("sv");
    await axios
      .get(`/api/hhms/bookings/?status=CHECKEDIN&checkout=${today}`)
      .then((response) => (this.bookings = response.data));
  },
  methods: {
    bookingDetails(booking) {
      this.$router.push({
        name: "Checkout",
        params: { bookingId: booking.id },
      });
    },
  },
};
</script>

<style scoped></style>
