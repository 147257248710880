git
<template>
  <div>
    <br />
    <b-container>
      <b-card class="mb-3" header="Payment Form">
        <b-form v-if="show" class="p-3" align="left">
          <b-form-group
            id="booking-group-2"
            label="Checkin Date:"
            label-for="input-2"
            description="The anticipated checkin date."
          >
            <b-form-input
              id="input-2"
              v-model="formData.check_in"
              type="date"
              placeholder="Checkin Date"
              :disabled="1"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            id="booking-group-3"
            label="Checkout Date:"
            label-for="input-3"
            description="The checkout date."
          >
            <b-form-input
              id="input-3"
              v-model="formData.check_out"
              type="date"
              placeholder="Checkout Date"
              :disabled="1"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            id="booking-group-4"
            label="Status:"
            label-for="input-4"
            description="The status."
          >
            <b-form-select
              v-model="formData.status"
              :options="statuses"
              :disabled="1"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="booking-group-5"
            label="Bed:"
            label-for="input-5"
            description="The bed reserved."
          >
            <b-form-select
              v-model="formData.bed"
              :options="empties"
              value-field="id"
              text-field="bed_name"
              :disabled="1"
              required
            >
            </b-form-select>
          </b-form-group>
          <b-form-group
            id="booking-group-6"
            label="Guest:"
            label-for="input-6"
            description="The guest making reservation."
          >
            <b-form-input
              id="input-6"
              v-model="formData.guest_name"
              type="text"
              placeholder="Guest"
              :disabled="1"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            id="booking-group-7"
            label="Square Transaction ID:"
            label-for="input-7"
            description="Enter the square transaction ID here"
          >
            <b-form-input
              id="input-6"
              v-model="transactionId"
              type="text"
              placeholder=""
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            id="booking-group-8"
            label="Payment Required:"
            label-for="input-8"
            description="The total amound required"
          >
            <b-form-input
              id="input-6"
              v-model="paymentAmount"
              type="number"
              placeholder=""
              required
            >
            </b-form-input>
          </b-form-group>
          <b-row cols="6">
            <b-col cols="3" class="mr-auto p-3">
              <b-button type="reset" variant="danger" style="padding: 5px"
                >Reset
              </b-button>
            </b-col>
            <b-col cols="auto" class="p-3">
              <div>
                <b-button
                  @click="takePayment()"
                  variant="success"
                  style="padding: 5px"
                  >Confirm Payment
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { getEmptyBeds, status } from "@/helpers.js";
import { getPaymentAmounts } from "@/helpers";

export default {
  name: "PaymentForm",
  data() {
    return {
      show: true,
      submitted: false,
      newBooking: false,
      statuses: status,
      empties: Array,
      amounts: Array,
      transactionId: null,
    };
  },
  props: {
    booking: Object,
  },
  async created() {
    const response = await getEmptyBeds();
    this.empties = response.data;
    const amount_response = await getPaymentAmounts();
    this.amounts = amount_response.data;
  },
  computed: {
    formData: function () {
      return this.booking || {};
    },
    paymentAmount: function () {
      let checkin = new Date(this.formData.check_in);
      let checkout = new Date(this.formData.check_out);
      let difference = this.dateDiffInDays(checkout, checkin)
      let paymentTotal = 0;
      if (difference < 7) {
        paymentTotal = difference * 15;
      } else if (difference === 7) {
        paymentTotal = 70;
      } else if (difference === 30) {
        paymentTotal = 250;
      }
      console.log(`${difference}`);
      return paymentTotal;
    },
  },
  methods: {
    dateDiffInDays(checkout, checkin) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffInTime = Math.ceil(checkout - checkin);
      const diffInDays = Math.abs(Math.round(diffInTime / oneDay));
      return diffInDays;
    },
    async takePayment() {
      this.formData.check_in = `${this.formData.check_in}`;
      this.formData.check_out = `${this.formData.check_out}`;
      this.formData.status = "CHECKEDIN";
      this.formData.amount = this.paymentAmount;
      let payment = {
        type: "SQUARE",
        amount: this.paymentAmount,
        payment_type: "SQUARE",
        payment_detail: this.transactionId,
        booking: this.formData.id,
        status: "PAID",
      };

      let booking_update = {
        id: this.formData.id,
        status: this.formData.status,
      };
      try {
        await axios
          .patch("/api/hhms/bookings/" + this.formData.id + "/", booking_update)
          .then(() => {
            Promise.all([
              axios.post("/api/hhms/transactions/", payment),
              axios.patch("/api/hhms/beds/" + this.formData.bed + "/", {
                status: "OCCUPIED",
              }),
            ]).then(() => {
              this.$router.push("/bookings");
            });
          });
      } catch (err) {
        this.$bvToast.toast(
          `Error caused by Duplicated booking. ${err.toJSON().message}`,
          {
            title: "Error Confirming Payment",
            variant: "danger",
            solid: true,
            noAutoHide: true,
            bodyClass: "mb-4",
          }
        );
      }
    },
    isEmptyObject() {
      if (this.booking == null) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
